<template lang="">
    <v-card>
        
        <s-crud
            :config="config"
            title="Personal no Sujetos a Ficalización"
            :filter="filter"
            search-input
            add
            edit
            remove
            @save="save($event)"
            ref="scrudPersonnelNotSubjects"
        >
            <template v-slot="props">
                <v-container>
                    <v-row >                                        
                        <v-col cols="12" class="s-col-form" sm="2">
                            <s-toolbar-person label="Buscar Persona" noCamera v-model="props.item.PrsDocumentNumber" @returnPerson="returnPerson($event, props.item)"/>
                        </v-col>
                        <v-col cols="12" class="s-col-form" sm="3">
                            <s-text label="Nombre" v-model="props.item.NtpFullName" disabled></s-text>
                        </v-col>
                        <v-col cols="12" class="s-col-form" sm="2">
                            <s-text label="PRSID" v-model="props.item.PrsID" disabled></s-text>
                        </v-col>
                        <v-col cols="12" class="s-col-form" sm="2">
                            <s-text label="NTPID" v-model="props.item.NtpID" disabled></s-text>
                        </v-col>
                        <v-col cols="12" class="s-col-form" sm="3">
                            <s-select-position label="Cargo" v-model="props.item.PstID" :text="props.item.PstName"/>
                        </v-col>
                    </v-row>
                </v-container>
            </template>
        </s-crud>
    </v-card>

</template>

<script>

import SText from '../../components/Utils/SText.vue';
import HmnPersonnelNotSubjects from '../../services/HumanResource/HmnPersonnelNotSubjects';
import sToolbarPerson from "@/components/Utils/Person/sToolbarPerson";

export default {
    components: {
        SText,
        sToolbarPerson
    },
    data() {
        return {
            filter: {
            },
            config: {
                service: HmnPersonnelNotSubjects,
                model: {
                    PnsID: "ID",
                },
                headers: [
                    { text: "ID", value: "PnsID" },
                    { text: "Nombre", value: "NtpFullName" },
                    { text: "DNI", value: "PrsDocumentNumber" },
                    { text: "Cargo", value: "PstName" },
                ]
            }
        }
    },
    methods: {
        save(item){
            console.log(item);
            if(item.PrsID == null || item.NtpID == 0){
                this.$fun.alert("Seleccione una persona","warning");
                return;
            }

            if(item.PstID == null){
                this.$fun.alert("Seleccione un cargo","warning");
                return;
            }

            item.UsrCreateID = this.$fun.getUserID();
            item.save();
        },
        returnPerson(value, item) {
            if(value != null){
                item.PrsID = value.PrsID;
                item.NtpID = value.NtpID;
                item.NtpFullName = value.NtpFullName;
            }
        }
    }
}

</script>